<template>
  <div>
    <el-dialog
      :title="title1"
      :visible.sync="dialogVisible1"
      width="40%"
      :close-on-click-modal="false"
      :show-close="false"
    >
      <el-form label-width="98px" :model="form">
        <el-form-item label="场次标题">
          <el-input
            v-model="form.reserveTitle"
            placeholder="请输入32个字以内的场次标题"
            maxlength="32"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="本场培训时间">
          <el-date-picker
            v-model="form.time1"
            type="date"
            placeholder="选择日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
          <el-time-picker
            v-model="form.time2"
            is-range
            style="margin-left:5px"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            placeholder="选择时间范围"
            format="HH:mm:ss"
            value-format="HH:mm:ss"
          >
          </el-time-picker>
        </el-form-item>
        <el-form-item label="本场签到时间">
          <el-date-picker
            v-model="form.time3"
            type="date"
            placeholder="选择日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
          <el-time-picker
            v-model="form.time4"
            style="margin-left:5px"
            is-range
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            placeholder="选择时间范围"
            format="HH:mm:ss"
            value-format="HH:mm:ss"
          >
          </el-time-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleAddClose()">取 消</el-button>
        <el-button type="primary" @click="handleAdd()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="地址选择"
      :visible.sync="dialogVisible"
      width="70%"
      :close-on-click-modal="false"
      :show-close="false"
    >
      <getMapLatLng @getLatLng="getDataAddress"></getMapLatLng>
      <!-- <el-input
        v-model="keyword"
        type="text"
        placeholder="请输入地址"
        maxlength="256"
        show-word-limit
      ></el-input>
      <baidu-map class="map" center="北京" :scroll-wheel-zoom="true">
        <bm-scale anchor="BMAP_ANCHOR_TOP_LEFT"></bm-scale>
        <bm-city-list anchor="BMAP_ANCHOR_TOP_LEFT"></bm-city-list>
        <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_LEFT"></bm-geolocation>
        <bm-local-search
          class="city"
          :keyword="keyword"
          :auto-viewport="true"
          :page-capacity="10"
          @infohtmlset="infohtmlset"
        ></bm-local-search>
      </baidu-map> -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="chooseBtn">取 消</el-button>
        <el-button type="primary" @click="okBtn">确 定</el-button>
      </span>
    </el-dialog>
    <div class="wrap">
      <div class="title1">场次签到</div>
      <div>
        <el-button
          type="primary"
          class="addBtn"
          @click="
            dialogVisible1 = true
            title1 = '添加场次'
          "
          >添加场次</el-button
        >
      </div>
      <div class="title">
        <span class="th1">场次标题</span>
        <span class="th2">签到时间</span>
        <span class="th3">培训时间</span>
        <span class="th4">操作</span>
      </div>
      <div v-for="(item, index) in formDetail.dto.list" :key="item.userName" class="title2">
        <span class="th1">{{ item.reserveTitle }}</span>
        <span class="th2">{{ item.signTime }}</span>
        <span class="th3">{{ item.trainTime }}</span>
        <span class="th4">
          <!-- <img
            v-show="index != 0"
            class="icon1"
            src="../../../assets/teacher/up_icon.png"
            alt="上移"
            @click="handleUp(item, index)"
          />
          <img
            v-show="index == 0"
            class="icon1"
            src="../../../assets/teacher/noup_icon.png"
            alt=""
            @click="handleUp(item, index)"
          />
          <img
            v-show="index != formDetail.dto.list.length - 1"
            class="icon2"
            src="../../../assets/teacher/down_icon.png"
            alt="下移"
            @click="handleDown(item, index)"
          />
          <img
            v-show="index == formDetail.dto.list.length - 1"
            class="icon2"
            src="../../../assets/teacher/nodown_icon.png"
            alt=""
            @click="handleDown(item, index)"
          /> -->
          <img
            class="icon3"
            src="../../../assets/teacher/edit_icon.png"
            alt="编辑"
            @click="handleEdit(item, index)"
          />
          <img
            class="icon4"
            src="../../../assets/teacher/delete_icon.png"
            alt="删除"
            @click="handleDelete(item, index)"
          />
        </span>
      </div>
      <div class="title">其他设置</div>
      <div class="form_box">
        <el-form label-width="140px" :model="formDetail">
          <el-form-item label="是否限制签到地点：">
            <el-radio-group v-model="formDetail.limitAddress">
              <el-radio label="1">限制</el-radio>
              <el-radio label="2">不限制</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-show="formDetail.limitAddress == '1'" label="签到地址：">
            <div class="chooseBtn" @click="dialogVisible = true">
              {{ formDetail.detailAddress }}
              <i class="el-icon-location-outline"></i>选择地址
            </div>
          </el-form-item>
          <div v-show="formDetail.limitAddress == '1'" class="box_wrap">
            <div style="width:140px;padding: 0 12px 0 0;text-align: right;box-sizing: border-box;">
              签到范围限制
              <el-tooltip
                class="item"
                effect="dark"
                content="以签到地址为中心范围为半径都可以签到"
                placement="top-start"
              >
                <img
                  src="../../../assets/teacher/tips_icon.png"
                  alt="以签到地址为中心范围为半径都可以签到"
                />
              </el-tooltip>
              :
            </div>
            <span
              :class="[formDetail.signRange == 100 ? 'light' : 'nolight']"
              @click="checkLong(100)"
            >
              100米内
            </span>
            <span
              :class="[formDetail.signRange == 200 ? 'light' : 'nolight']"
              @click="checkLong(200)"
            >
              200米内
            </span>
            <span
              :class="[formDetail.signRange == 500 ? 'light' : 'nolight']"
              @click="checkLong(500)"
            >
              500米内 </span
            ><span
              :class="[formDetail.signRange == 1000 ? 'light' : 'nolight']"
              @click="checkLong(1000)"
            >
              1000米内 </span
            ><span
              :class="[formDetail.signRange == 1500 ? 'light' : 'nolight']"
              @click="checkLong(1500)"
            >
              1500米内
            </span>
          </div>
        </el-form>
      </div>
      <el-button type="primary" class="subBtn" @click="handleSubmit()">保存</el-button>
    </div>
  </div>
</template>
<script>
import getMapLatLng from './map.vue'
import to from 'await-to'
import {
  reserveDetailSave,
  reserveDetailPage,
  reserveDetailEdit,
  reserveDetailDelete,
  reserveDetailSort,
} from '@/api/teacher'
export default {
  components: { getMapLatLng },
  data() {
    return {
      reserveTeacherId: this.$route.query.teacherId,
      dialogVisible: false,
      dialogVisible1: false,
      title1: '',
      keyword: '',
      form: {
        reserveTitle: '', //场次标题
        id: '', //场次ID
        signTimeEnd: '', //签到结束
        signTimeStart: '', //签到开始
        trainTimeEnd: '', //培训结束
        trainTimeStart: '', //培训开始
        time1: '',
        time2: '',
        time3: '',
        time4: '',
      },
      formDetail: {
        dto: {},
      },
      queryParams: {},
    }
  },
  created() {
    this.getReserveDetailPage()
  },
  methods: {
    async getReserveDetailPage() {
      const [res, err] = await to(
        reserveDetailPage({
          reserveTeacherId: this.reserveTeacherId,
          current: 1,
          size: 99,
        }),
      )
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.formDetail = res.data
      this.formDetail.reserveTeacherId = this.reserveTeacherId
    },
    //选择限制范围
    checkLong(val) {
      this.formDetail.signRange = val
    },
    getDataAddress(res) {
      console.log(res)
      this.formDetail.latitude = res[0]
      this.formDetail.longitude = res[1]
      this.formDetail.detailAddress = res[2]
    },
    //确定地址
    okBtn() {
      if (this.formDetail.detailAddress == null) {
        this.$message({
          message: '请选择签到地址',
          type: 'warning',
        })
      } else {
        this.dialogVisible = false
      }
    },
    //取消地址
    chooseBtn() {
      this.formDetail.detail_address = ''
      this.formDetail.province_name = ''
      this.formDetail.city_name = ''
      this.formDetail.longitude = ''
      this.formDetail.latitude = ''
      this.dialogVisible = false
    },
    //添加场次
    async handleAdd() {
      if (this.form.reserveTitle == '')
        return this.$message({ type: 'error', message: '请填写场次标题' })
      if (this.form.time1 == '') return this.$message({ type: 'error', message: '请选择培训时间' })
      if (this.form.time2 == '') return this.$message({ type: 'error', message: '请选择培训时间' })
      if (this.form.time3 == '') return this.$message({ type: 'error', message: '请选择签到时间' })
      if (this.form.time4 == '') return this.$message({ type: 'error', message: '请选择签到时间' })
      this.form.trainTimeStart = this.form.time1 + ' ' + this.form.time2[0]
      this.form.trainTimeEnd = this.form.time1 + ' ' + this.form.time2[1]
      this.form.signTimeStart = this.form.time3 + ' ' + this.form.time4[0]
      this.form.signTimeEnd = this.form.time3 + ' ' + this.form.time4[1]
      if (this.form.id == '') {
        const [, err] = await to(
          reserveDetailSave({
            reserveTeacherId: this.reserveTeacherId,
            reserveTitle: this.form.reserveTitle,
            trainTimeStart: this.form.trainTimeStart,
            trainTimeEnd: this.form.trainTimeEnd,
            signTimeStart: this.form.signTimeStart,
            signTimeEnd: this.form.signTimeEnd,
          }),
        )
        if (err) return this.$message({ type: 'error', message: err.msg })
        this.$message({ type: 'success', message: '添加成功' })
        this.dialogVisible1 = false
        this.form.reserveTitle = ''
        this.form.id = ''
        this.form.trainTimeEnd = ''
        this.form.trainTimeStart = ''
        this.form.signTimeEnd = ''
        this.form.signTimeStart = ''
        this.form.time1 = ''
        this.form.time2 = ''
        this.form.time3 = ''
        this.form.time4 = ''
        this.getReserveDetailPage()
      }
      if (this.form.id != '') {
        const [, err] = await to(
          reserveDetailEdit({
            reserveTeacherId: this.reserveTeacherId,
            reserveTitle: this.form.reserveTitle,
            id: this.form.id,
            trainTimeStart: this.form.trainTimeStart,
            trainTimeEnd: this.form.trainTimeEnd,
            signTimeStart: this.form.signTimeStart,
            signTimeEnd: this.form.signTimeEnd,
          }),
        )
        if (err) return this.$message({ type: 'error', message: err.msg })
        this.$message({ type: 'success', message: '编辑成功' })
        this.dialogVisible1 = false
        this.form.reserveTitle = ''
        this.form.id = ''
        this.form.trainTimeEnd = ''
        this.form.trainTimeStart = ''
        this.form.signTimeEnd = ''
        this.form.signTimeStart = ''
        this.form.time1 = ''
        this.form.time2 = ''
        this.form.time3 = ''
        this.form.time4 = ''
        this.getReserveDetailPage()
      }
    },
    handleAddClose() {
      this.dialogVisible1 = false
      this.form.reserveTitle = ''
      this.form.id = ''
      this.form.trainTimeEnd = ''
      this.form.trainTimeStart = ''
      this.form.signTimeEnd = ''
      this.form.signTimeStart = ''
      this.form.time1 = ''
      this.form.time2 = ''
      this.form.time3 = ''
      this.form.time4 = ''
    },
    //上移
    handleUp(item, index) {
      if (index == 0) {
        return this.$message.warning('已经是第一项了')
      }
      this.formDetail.dto.list.splice(index - 1, 0, this.formDetail.dto.list[index])
      this.formDetail.dto.list.splice(index + 1, 1)
    },
    //下移
    handleDown(item, index) {
      if (index == this.formDetail.dto.list.length - 1) {
        return this.$message.warning('已经是最后一项了')
      }
      this.formDetail.dto.list.splice(index + 2, 0, this.formDetail.dto.list[index])
      this.formDetail.dto.list.splice(index, 1)
    },
    //编辑
    handleEdit(item) {
      this.form.reserveTitle = item.reserveTitle
      this.form.time1 = item.trainTimeStart.split('T')[0]
      this.form.time2 = [item.trainTimeStart.split('T')[1], item.trainTimeEnd.split('T')[1]]
      this.form.time3 = item.signTimeStart.split('T')[0]
      this.form.time4 = [item.signTimeStart.split('T')[1], item.signTimeEnd.split('T')[1]]
      this.dialogVisible1 = true
      this.title1 = '编辑场次'
      this.form.id = item.id
    },
    //删除
    async handleDelete(item) {
      const [, err] = await to(reserveDetailDelete({ id: item.id }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.$message({ type: 'success', message: '删除成功' })
      this.getReserveDetailPage()
    },
    async handleSubmit() {
      if (this.formDetail.limitAddress == null)
        return this.$message({ type: 'warning', message: '请选择是否限制签到地点' })
      if (this.formDetail.limitAddress == '1' && this.formDetail.detailAddress == null)
        return this.$message({ type: 'warning', message: '请选择签到地址' })
      if (this.formDetail.limitAddress == null && this.formDetail.signRange == null)
        return this.$message({ type: 'warning', message: '请选择签到范围' })

      const [, err] = await to(reserveDetailSort(this.formDetail))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.$message({ type: 'success', message: '保存成功' })
      this.$router.push('/teacher/myAboutList')
    },
  },
}
</script>
<style scoped>
.map {
  width: 100%;
  height: 520px;
  margin-top: 10px;
  position: relative;
}
.city {
  width: 30%;
  height: 520px;
  overflow-y: scroll;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 14px;
}
.wrap {
  background-color: #fff;
}
.title {
  height: 54px;
  line-height: 54px;
  background-color: #f6f6f6;
  padding-left: 26px;
  margin: 30px 26px 10px 26px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  display: flex;
}
.title1 {
  height: 54px;
  line-height: 54px;
  font-size: 18px;
  padding: 0 26px;
  font-weight: 500;
  color: #333333;
  border-bottom: 1px solid #e2e2e2;
}
.title2 {
  height: 54px;
  line-height: 54px;
  padding-left: 26px;
  margin: 0 26px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  display: flex;
  border-bottom: 1px solid #e2e2e2;
}
.chooseBtn {
  color: #ff7b33;
  font-size: 14px;
  cursor: pointer;
}
.el-form {
  padding: 0 26px;
}
.addBtn {
  margin: 25px 26px 0 26px;
}
.th1 {
  width: 35%;
}
.th2 {
  width: 25%;
}
.th3 {
  width: 25%;
}
.th4 {
  width: 15%;
  min-width: 180px;
}
.subBtn {
  margin: 50px 162px;
}
.th4 img {
  cursor: pointer;
  margin-right: 26px;
}
.icon1 {
  width: 22px;
  height: 16px;
}
.icon2 {
  width: 21px;
  height: 15px;
}
.icon3 {
  width: 18px;
  height: 17px;
}
.icon4 {
  width: 13px;
  height: 18px;
}
.box_wrap {
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  color: #606266;
  display: flex;
}
.box_wrap img {
  width: 14px;
  height: 14px;
  margin: 5px 3px 0 0;
  cursor: pointer;
}
.form_box >>> .el-form-item__content {
  line-height: 24px !important;
}
.form_box >>> .el-form-item__label {
  line-height: 24px !important;
}
.light {
  width: 84px;
  height: 24px;
  line-height: 24px;
  background: #ffffff;
  border: 1px solid #ff7b33;
  border-radius: 4px;
  text-align: center;
  font-size: 14px;
  color: #ff7b33;
  margin: 8px 18px 0 0;
  cursor: pointer;
}
.nolight {
  width: 84px;
  height: 24px;
  line-height: 24px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  text-align: center;
  font-size: 14px;
  color: #909399;
  margin: 8px 18px 0 0;
  cursor: pointer;
}
</style>
